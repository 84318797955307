@import '~site/variables';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import '~site/settings/variables';
@import '~site/mixins';

.photo-tile-figure {
    margin: 0;
    width: 100%;
}

.photo-tile-image {
    object-fit: cover;
    width: 100%;
}

.photo-tile-figcaption {
    @include description($sm-line-height: 1.25, $color: $primary, $multiple-size: false);

    font-weight: 500;
    letter-spacing: rem-calc(2);
    margin: 1.5em 0 0;
    text-transform: uppercase;
}
